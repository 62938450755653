import { Card, Modal, Paper, Stack, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubProjectContext } from "../../../data/contexts/SubProjectContext";
import { signerToSignerInput } from "../../../data/dataConvertors";
import { SignerInput } from "../../../data/generated/graphql";
import CompanyStep from "./CompanyStep";
import SignerStep from "./SignerStep";
import SiretStep from "./SiretStep";

interface CompanyModalProps {
  open: boolean;
  onClose: () => void;
  subProjectId: string;
}

const CompanyModal: FC<CompanyModalProps> = ({ open, onClose, subProjectId }) => {
  const { t } = useTranslation(["vehicles", "project"]);
  const { addVehicleList } = useContext(SubProjectContext);
  const [signer, setSigner] = useState<SignerInput>({ company: { siret: "" }, role: "Président" });
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (): void => {
    setStep(1);
    setSigner({ company: { siret: "" }, role: "Président" });
    setIsLoading(false);
    onClose();
  };

  const handleSave = async (): Promise<boolean> => {
    setIsLoading(true);
    if (typeof signer !== "undefined") {
      await addVehicleList(subProjectId, signerToSignerInput(signer));
      setIsLoading(false);
      closeModal();
      return true;
    }
    setIsLoading(false);
    closeModal();
    return false;
  };

  const getStepComponent = (): JSX.Element | undefined => {
    switch (step) {
      case 1:
        return <SiretStep signer={signer} setSigner={setSigner} setStep={setStep} />;
      case 2:
        return <CompanyStep signer={signer} setSigner={setSigner} setStep={setStep} />;
      case 3:
        return (
          <SignerStep
            signer={signer}
            setSigner={setSigner}
            setStep={setStep}
            isLoading={isLoading}
            handleSave={handleSave}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Modal open={open} onClose={closeModal} className="project-modal-root">
      <Paper sx={{ padding: "32px", width: step === 1 ? "400px" : "800px" }}>
        <Stack direction="column" gap="32px" justifyContent="space-between" height="100%" alignItems="center">
          <Typography variant="h5">{t("addCompany")}</Typography>
          {step !== 1 ? (
            <Card sx={{ padding: "8px", width: "700px", textAlign: "center" }}>
              <Stack direction="column" gap="8px" justifyContent="center" flexWrap="wrap">
                <Typography>
                  {t("project:siret")} : {signer.company.siret}
                </Typography>
                {step === 3 ? (
                  <>
                    <Typography>
                      {t("project:company.name")} : {signer.company.name}
                    </Typography>
                    <Stack direction="row" gap="8px" justifyContent="center">
                      <Typography>
                        {t("project:company.address")} : {signer.company.address}
                      </Typography>
                      <Typography>{signer.company.zipCode}</Typography>
                      <Typography>{signer.company.city}</Typography>
                    </Stack>
                  </>
                ) : undefined}
              </Stack>
            </Card>
          ) : undefined}
          <Stack direction="column" gap="16px" alignItems="center" width="100%">
            {getStepComponent()}
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default CompanyModal;
