import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Tooltip } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import PhoneTextField from "../../../common/PhoneTextField";
import { Gender, SignerInput } from "../../../data/generated/graphql";
import CenteredContent from "../../../utils/CenteredContent";
import GdButton from "../../../utils/GdButton";
import WarningCard from "../../../utils/WarningCard";

interface SignerStepProps {
  signer: SignerInput;
  setSigner: (signer: SignerInput) => void;
  setStep: (step: number) => void;
  handleSave: () => Promise<boolean>;
  isLoading: boolean;
}

const SignerStep: FC<SignerStepProps> = ({ signer, setSigner, setStep, handleSave, isLoading }) => {
  const { t } = useTranslation(["vehicles", "user"]);

  const isDisbaled =
    !signer.gender ||
    signer.gender.length === 0 ||
    !signer.firstName ||
    signer.firstName.length === 0 ||
    !signer.lastName ||
    signer.lastName.length === 0 ||
    !signer.phone ||
    signer.phone.length === 0 ||
    !signer.email ||
    signer.email.length === 0;

  const changeSignerField = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) =>
    setSigner({ ...signer, [field]: typeof e === "string" ? e : e.target.value });
  const changeSignerFieldFromSelect = (field: string) => (e: SelectChangeEvent<string | null>) =>
    setSigner({ ...signer, [field]: e.target.value });

  return (
    <>
      <WarningCard projectLabelKey={t("fleetSignerWarning")} className="margin-bottom" />
      <Stack direction="row" gap="16px" width="700px">
        <FormControl style={{ width: "100px" }}>
          <InputLabel id="civ-select-label">{t("user:civility")}</InputLabel>
          <Select
            labelId="civ-select-label"
            label={t("user:civility")}
            variant="outlined"
            value={signer.gender}
            onChange={changeSignerFieldFromSelect("gender")}>
            <MenuItem value={undefined}>
              <em>{t("user:civ.select")}</em>
            </MenuItem>
            <MenuItem value={Gender.M}>{t("user:civ.mr")}</MenuItem>
            <MenuItem value={Gender.F}>{t("user:civ.mrs")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ width: "300px" }}
          label={t("user:lastName")}
          variant="outlined"
          value={signer.lastName}
          onChange={changeSignerField("lastName")}
          className="not-only-textfield"
          autoComplete="off"
        />
        <TextField
          sx={{ width: "300px" }}
          label={t("user:firstName")}
          variant="outlined"
          value={signer.firstName}
          onChange={changeSignerField("firstName")}
          className="not-only-textfield"
          autoComplete="off"
        />
      </Stack>
      <Stack direction="row" gap="16px" width="700px">
        <PhoneTextField
          style={{ width: "180px" }}
          label={t("user:phone")}
          value={signer.phone || ""}
          onChange={changeSignerField("phone")}
        />
        <TextField
          sx={{ width: "400px" }}
          label={t("user:email")}
          variant="outlined"
          value={signer.email}
          onChange={changeSignerField("email")}
          className="not-only-textfield"
          autoComplete="off"
        />

        <Tooltip title={<span>{t("project:presidentTooltip")}</span>} arrow placement="top">
          <TextField
            label={t("user:role")}
            variant="outlined"
            value={signer.role}
            className="not-only-textfield"
            disabled
            autoComplete="off"
          />
        </Tooltip>
      </Stack>
      <Stack direction="row" gap="16px" justifyContent="center" marginTop="16px" maxWidth="400px">
        <GdButton disabled={isLoading} label={t("previous")} color="inherit" onClick={() => setStep(2)} />
        {isLoading ? (
          <CenteredContent loading loadingSize={20} />
        ) : (
          <GdButton label={t("addCompany")} onClick={handleSave} disabled={isDisbaled} />
        )}
      </Stack>
    </>
  );
};

export default SignerStep;
