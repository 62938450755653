import { ApolloError } from "@apollo/client";
import { createContext, FC, PropsWithChildren, useState } from "react";
import {
  Signer,
  SubProject,
  useSubProjectAddVehicleListMutation,
  useSubProjectEnrollMeAsDelegateMutation,
  useSubProjectEnrollMeMutation,
  useSubprojectGetMyFleetSubProjectsQuery,
  useSubProjectUpdateVehicleMutation,
  VehicleInput,
} from "../generated/graphql";

export interface SubProjectContextProps {
  fleetSubProjects: SubProject[];
  getMyFleetSubProjects(force?: boolean): Promise<void>;
  fleetSubProjectsLoading: boolean;
  fleetSubProjectsError?: ApolloError;
  saveSubProjects: (subProj: SubProject[]) => void;
  enrollToSubProject: (projectId: string) => Promise<void>;
  enrollAsFleetManagerToVehicleList: (vehicleListId: string) => Promise<void>;
  addVehicleList: (subProjectId: string, signer: Signer) => Promise<void>;
  updateVehicle: (vehicleListId: string, vehicle: VehicleInput) => Promise<void>;
}

const initialContext: SubProjectContextProps = {
  fleetSubProjects: [],
  getMyFleetSubProjects: () => Promise.resolve(),
  fleetSubProjectsLoading: false,
  saveSubProjects: () => {},
  enrollToSubProject: () => Promise.resolve(),
  enrollAsFleetManagerToVehicleList: () => Promise.resolve(),
  addVehicleList: () => Promise.resolve(),
  updateVehicle: () => Promise.resolve(),
};

export const SubProjectContext = createContext<SubProjectContextProps>(initialContext);

export const SubProjectProvider: FC<PropsWithChildren> = ({ children }) => {
  const [fleetSubProjects, setFleetSubProjects] = useState<SubProject[]>([]);
  const [latestCheck, setLatestCheck] = useState(0);
  const {
    loading: fleetSubProjectsLoading,
    error: fleetSubProjectsError,
    refetch: fleetSubProjectsRefetch,
  } = useSubprojectGetMyFleetSubProjectsQuery({ skip: true });
  const [subProjectEnrollMe] = useSubProjectEnrollMeMutation();
  const [subProjectEnrollMeAsDelegate] = useSubProjectEnrollMeAsDelegateMutation();
  const [vehcileListAdd] = useSubProjectAddVehicleListMutation();
  const [vehicleUpdate] = useSubProjectUpdateVehicleMutation();

  const saveSubProjects = (subProj: SubProject[]): void => {
    setFleetSubProjects(subProj);
    setLatestCheck(new Date().getTime());
  };

  const getMyFleetSubProjects = async (force = false): Promise<void> => {
    // Check for new projects every 20 minutes
    if (latestCheck < new Date().getTime() - 20 * 60 * 1000 || force) {
      const result = await fleetSubProjectsRefetch();
      if (result && result.data) {
        const subProj = result.data.subprojectGetMyFleetSubProjects
          ? (result.data.subprojectGetMyFleetSubProjects as SubProject[])
          : [];
        setFleetSubProjects(subProj);
        setLatestCheck(new Date().getTime());
      }
    }
  };

  const enrollToSubProject = async (projectId: string): Promise<void> => {
    const result = await subProjectEnrollMe({ variables: { projectId } });
    if (result && result.data) {
      const newSubProject = result.data.subProjectEnrollMe as SubProject;
      let found = false;
      const newSubProjects = fleetSubProjects.map((p) => {
        if (p.projectId === newSubProject.projectId) {
          found = true;
          return newSubProject;
        }
        return p;
      });
      if (!found) newSubProjects.push(newSubProject);
      saveSubProjects(newSubProjects);
    }
  };

  const enrollAsFleetManagerToVehicleList = async (vehicleListId: string): Promise<void> => {
    const result = await subProjectEnrollMeAsDelegate({ variables: { vehicleListId } });
    if (result && result.data) {
      const newSubProject = result.data.subProjectEnrollMeAsDelegate as SubProject;
      let found = false;
      const newSubProjects = fleetSubProjects.map((p) => {
        if (p.projectId === newSubProject.projectId) {
          found = true;
          return newSubProject;
        }
        return p;
      });
      if (!found) newSubProjects.push(newSubProject);
      saveSubProjects(newSubProjects);
    }
  };

  const addVehicleList = async (subProjectId: string, signer: Signer): Promise<void> => {
    const result = await vehcileListAdd({ variables: { subProjectId, signer } });
    if (result && result.data) {
      const newSubProject = result.data.subProjectAddVehicleList as SubProject;
      let found = false;
      const newSubProjects = fleetSubProjects.map((p) => {
        if (p.id === newSubProject.id) {
          found = true;
          return newSubProject;
        }
        return p;
      });
      if (!found) newSubProjects.push(newSubProject);
      saveSubProjects(newSubProjects);
    }
  };

  const updateVehicle = async (vehicleListId: string, vehicle: VehicleInput): Promise<void> => {
    const result = await vehicleUpdate({ variables: { vehicleListId, vehicle } });
    if (result && result.data) {
      const newSubProject = result.data.subProjectUpdateVehicle as SubProject;
      let found = false;
      const newSubProjects = fleetSubProjects.map((p) => {
        if (p.id === newSubProject.id) {
          found = true;
          return newSubProject;
        }
        return p;
      });
      if (!found) newSubProjects.push(newSubProject);
      saveSubProjects(newSubProjects);
    }
  };

  return (
    <SubProjectContext.Provider
      value={{
        fleetSubProjects,
        getMyFleetSubProjects,
        fleetSubProjectsLoading,
        fleetSubProjectsError,
        saveSubProjects,
        enrollToSubProject,
        enrollAsFleetManagerToVehicleList,
        addVehicleList,
        updateVehicle,
      }}>
      {children}
    </SubProjectContext.Provider>
  );
};
