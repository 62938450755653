import {
  BusinessCenter,
  ChevronLeft,
  Dashboard,
  DirectionsCar,
  FormatListBulleted,
  Help,
  TipsAndUpdates,
} from "@mui/icons-material";
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
  Tooltip,
  styled,
} from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AssistantContext } from "../data/contexts/AssistantContext";
import { UserContext } from "../data/contexts/UserContext";
import GreenyPicto from "../utils/pictos/greeny-picto";
import { SubProjectContext } from "../data/contexts/SubProjectContext";

export const drawerWidth = 280;

const openedMixin = (theme: Theme): Record<string, unknown> => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): Record<string, unknown> => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface AppDrawerProps {
  open: boolean;
  closeDrawer: () => void;
  nav: (to: string) => void;
}

const AppDrawer: FC<AppDrawerProps> = ({ open, closeDrawer, nav }) => {
  const { actions } = useContext(UserContext);
  const { isAssistantActive } = useContext(AssistantContext);
  const { fleetSubProjects } = useContext(SubProjectContext);
  const { t } = useTranslation("global");
  return (
    <Drawer anchor="left" open={open} onClose={closeDrawer} variant="permanent">
      <div role="presentation">
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Tooltip title={t("dashboard")} arrow placement="right" disableHoverListener={open}>
            <ListItemButton key="dashboard" onClick={() => nav("/")}>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary={t("dashboard")} />
            </ListItemButton>
          </Tooltip>
          <Tooltip title={t("titles./actions")} arrow placement="right" disableHoverListener={open}>
            <ListItemButton key="actions" onClick={() => nav("/actions")}>
              <ListItemIcon>
                <Badge color="error" badgeContent={actions.length}>
                  <FormatListBulleted />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t("titles./actions")} />
            </ListItemButton>
          </Tooltip>
          <Tooltip title={t("titles./projects")} arrow placement="right" disableHoverListener={open}>
            <ListItemButton key="myProjects" onClick={() => nav("/projects")}>
              <ListItemIcon>
                <BusinessCenter />
              </ListItemIcon>
              <ListItemText primary={t("titles./projects")} />
            </ListItemButton>
          </Tooltip>
          {fleetSubProjects.length > 0 ? (
            <Tooltip title={t("titles./vehicles")} arrow placement="right" disableHoverListener={open}>
              <ListItemButton key="vehicles" onClick={() => nav("/fleet")}>
                <ListItemIcon>
                  <DirectionsCar />
                </ListItemIcon>
                <ListItemText primary={t("titles./vehicles")} />
              </ListItemButton>
            </Tooltip>
          ) : undefined}
        </List>
        <Divider />
        <List>
          {isAssistantActive ? (
            <Tooltip title={t("titles./assistantHistory")} arrow placement="right" disableHoverListener={open}>
              <ListItemButton key="assistantHistory" onClick={() => nav("/assistantHistory")}>
                <ListItemIcon>
                  <GreenyPicto width={24} height={24} fill="currentColor" />
                </ListItemIcon>
                <ListItemText primary={t("titles./assistantHistory")} />
              </ListItemButton>
            </Tooltip>
          ) : undefined}
          <Tooltip title={t("titles./tips")} arrow placement="right" disableHoverListener={open}>
            <ListItemButton key="tips" onClick={() => nav("/tips")}>
              <ListItemIcon>
                <TipsAndUpdates />
              </ListItemIcon>
              <ListItemText primary={t("titles./tips")} />
            </ListItemButton>
          </Tooltip>
          <Tooltip title={t("titles./faq")} arrow placement="right" disableHoverListener={open}>
            <ListItemButton key="faq" onClick={() => nav("/faq")}>
              <ListItemIcon>
                <Help />
              </ListItemIcon>
              <ListItemText primary={t("titles./faq")} />
            </ListItemButton>
          </Tooltip>
        </List>
      </div>
    </Drawer>
  );
};

export default AppDrawer;
